<template>
  <div class="row">
    <audio id="barcode_success" :src="`${$apiUrl}/audio/barcode-success.mp3`" preload type="audio/mpeg" style="height: 1px; width: 1px; visibility: hidden"></audio>
    <audio id="barcode_error" :src="`${$apiUrl}/audio/barcode-error.mp3`" preload type="audio/mpeg" style="height: 1px; width: 1px; visibility: hidden"></audio>
    <div class="col-12 col-md-12">
      <b-form @submit.prevent="generateSheet">
        <div class="row mb-2">
          <div class="col-12 col-md-6">
            <label>Choose Items/Products</label>
            <v-select v-model="item_id" :reduce="i => i.value" :options="itemOpts"
              :loading="loading">
              <template #search="{ attributes, events }">
                <b-input-group class="input-group-merge">
                  <input class="vs__search" :required="!item_id" v-bind="attributes" v-on="events" />
                  <b-input-group-append is-text>
                    <img
                      @click.prevent="enableBarcodeScan = !enableBarcodeScan"
                      width="36"
                      style="cursor: pointer;"
                      :src="`${$apiUrl}/images/icons/barcode-scanner.png`"
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </v-select>
          </div>
          <div v-if="enableBarcodeScan" class="col-12 col-md-6">
            <StreamBarcodeReader @decode="onDecodeBarcode" @loaded="barcodeIsReady"></StreamBarcodeReader>
          </div>
        </div>
        <div v-if="!isEmpty(sku)" class="row mb-2">
          <div class="col-12 col-md-6">
            <label>SKU</label>
            <b-form-input required type="text" readonly disabled v-model="sku" />
          </div>
        </div>
        <div v-if="!isEmpty(sku)" class="row mb-2">
          <div class="col-12 col-md-6">
            <label>Quantities</label>
            <b-form-input required type="number" min="1" step="1" v-model="quantities" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-md-6">
            <label>Barcode Type</label>
            <b-form-select v-model="barcodeType" required :options="barcodeTypeOpts"></b-form-select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-md-6">
            <label>Paper size</label>
            <b-form-select v-model="paperSize" required :options="paperSizeOpts"></b-form-select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-md-6">
            <b-button variant="primary" type="submit">Generate Sheet</b-button>
            <b-button class="ml-2" :disabled="!showBarcodeGrids" variant="primary" type="button" @click="printBarcode">Print</b-button>
          </div>
        </div>
      </b-form>
    </div>
    <div class="col-12">
      <div class="barcode-row" v-if="showBarcodeGrids" id="print_barcode_label">
        <div :class="class_type_page" v-for="(k, i) in total_a4" :key="i">
          <div class="barcode-item" :class="class_sheet" v-for="(sheet, index) in sheets" :key="index">
            <div class="head_barcode text-left" style=" padding-left: 10px;font-weight: bold; ">
              <span class="barcode-name">{{ item.item_name }}</span>
              <!-- <span class="barcode-price">{{ currentUser.currency }} {{ product.Net_price }}</span> -->
            </div>
            <barcode class="barcode" :format="barcodeType" :value="sku" textmargin="0" fontoptions="bold" fontSize="15"
              height="25" width="1"></barcode>
          </div>
        </div>
        <div :class="class_type_page" v-if="rest > 0">
          <div class="barcode-item" :class="class_sheet" v-for="(sheet, index) in rest" :key="index">
            <div class="head_barcode text-left" style=" padding-left: 10px;font-weight: bold; ">
              <span class="barcode-name">{{ item.item_name }}</span>
              <!-- <span class="barcode-price">{{ currentUser.currency }} {{ product.Net_price }}</span> -->
            </div>
            <barcode class="barcode" fontSize="15" :format="barcodeType" :value="sku"
              textmargin="0" fontoptions="bold" height="25" width="1"></barcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { isEmpty } from '@/utilities';
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Barcode from "vue-barcode";
import { StreamBarcodeReader } from "vue-barcode-reader";

const paperSizeOpts = [
  { text: 'Please select an option', value: null, disabled: true },
  { text: '40 per sheet (a4) (1.799 * 1.003)', value: 'style40' },
  { text: '30 per sheet (2.625 * 1)', value: 'style30' },
  { text: '24 per sheet (a4) (2.48 * 1.334)', value: 'style24' },
  { text: '20 per sheet (4 * 1)', value: 'style20' },
  { text: '18 per sheet (a4) (2.5 * 1.835)', value: 'style18' },
  { text: '14 per sheet (4 * 1.33)', value: 'style14' },
  { text: '12 per sheet (a4) (2.5 * 2.834)', value: 'style12' },
  { text: '10 per sheet (4 * 2)', value: 'style10' },
];
const barcodeTypeOpts = [
  { text: 'Code 128', value: 'CODE128' },
  { text: 'Code 39', value: 'CODE39' },
  { text: 'EAN8', value: 'EAN8', disabled: true},
  { text: 'EAN13', value: 'EAN13', disabled: true},
  { text: 'UPC', value: 'UPC', disabled: true},
];
export default {
  name: "GenerateBarcodeSheet",
  components: {
    vSelect, Barcode, StreamBarcodeReader
  },
  data: () => ({
    loading: true,
    paperSizeOpts,
    barcodeTypeOpts,
    paperSize: null,
    item_id: null,
    sku: null,
    barcodeType: "CODE128",
    items: [],
    itemOpts: [],
    quantities: 10,

    showBarcodeGrids: false,
    sheets: '',
    total_a4: [],
    class_sheet: '',
    class_type_page: '',
    rest: 0,
    enableBarcodeScan: false,
    barcodeScannerIsReady: true,
  }),
  computed: {
    storeInfo: {
      get() {
        return window.SuperApp.getters.userInfo()
      }
    },
    item: {
      get() {
        if (isEmpty(this.item_id)) return {};
        return this.items.find((i) => i.id === this.item_id)
      }
    }
  },
  methods: {
    isEmpty,
    async getItems() {
      axios.get(`${jwtDefaultConfig.apiEndpoint}/shop/itemsindex/${this.storeInfo.id}?page=all`).then(response => {
        const { data } = response
        if (data._statusCode === 200) {
          const items = data.responseData;
          this.items = items;
          this.itemOpts = items.filter((i) => !isEmpty(i.sku)).map((i) => ({
            label: `${i.item_name} (SKU: ${i.sku})`,
            value: i.id,
            disabled: i > 5
          }));
        }
        this.loading = false;
      });
    },
    selectPaper() {
      if (this.paperSize === 'style40') {
        this.sheets = 40;
        this.class_sheet = 'style40';
        this.class_type_page = 'barcodea4';
      } else if (this.paperSize === 'style30') {
        this.sheets = 30;
        this.class_type_page = 'barcode_non_a4';
        this.class_sheet = 'style30';
      } else if (this.paperSize === 'style24') {
        this.sheets = 24;
        this.class_sheet = 'style24';
        this.class_type_page = 'barcodea4';
      } else if (this.paperSize === 'style20') {
        this.sheets = 20;
        this.class_sheet = 'style20';
        this.class_type_page = 'barcode_non_a4';
      } else if (this.paperSize === 'style18') {
        this.sheets = 18;
        this.class_sheet = 'style18';
        this.class_type_page = 'barcodea4';
      } else if (this.paperSize === 'style14') {
        this.sheets = 14;
        this.class_sheet = 'style14';
        this.class_type_page = 'barcode_non_a4';
      } else if (this.paperSize === 'style12') {
        this.sheets = 12;
        this.class_sheet = 'style12';
        this.class_type_page = 'barcodea4';
      } else if (this.paperSize === 'style10') {
        this.sheets = 10;
        this.class_sheet = 'style10';
        this.class_type_page = 'barcode_non_a4';
      }
      this.calculatePage();
    },
    calculatePage() {
      this.total_a4 = parseInt(this.quantities / this.sheets);
      console.log(this.total_a4, 'this.total_a4');
      this.rest = this.quantities % this.sheets;
      console.log(this.rest, 'this.rest');
    },
    generateSheet() {
      this.selectPaper()
      this.showBarcodeGrids = true
    },
    printBarcode() {
      const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL;
      var divContents = document.getElementById("print_barcode_label").innerHTML;
      var a = window.open("", "", "height=500, width=500");
      a.document.write(
        `<link rel="stylesheet" href="${appUrl}/assets/css/print_barcode.css"><html>`
      );
      a.document.write("<body >");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      setTimeout(() => {
        a.print();
      }, 100);
    },
    barcodeIsReady(){
      this.barcodeScannerIsReady = true;
    },
    onDecodeBarcode(barcodeValue){
      console.log(barcodeValue, "barcodeValue")
      if(!this.barcodeScannerIsReady) return false;
      const barcode_error_a = document.getElementById('barcode_error');
      const barcode_success_a = document.getElementById('barcode_success');
      const item = this.items.find(i => i.sku == barcodeValue);
      if(isEmpty(item)){
        barcode_error_a.play();
        showErrorNotification(this, this.$t("Barcode not found"));
        return false;
      }
      this.barcodeScannerIsReady = false;
      barcode_success_a.play();
      this.item_id = item.id;
      this.barcodeScannerIsReady = true;
    }
  },
  watch: {
    item: {
      handler(nv) {
        this.sku = nv.sku;
        this.showBarcodeGrids = false;
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getItems();
  }

}
import 'vue-select/dist/vue-select.css';
import { showErrorNotification } from '@/@core/comp-functions/ui/app';
</script>
<style>
@media print{
	.barcode_non_a4 , .barcodea4{
		margin: 0;
	}

	.barcode_non_a4 , .barcode_non_a4 .barcode-item, .barcodea4 , .barcodea4 .barcode-item{
		border: none!important;
	}
}

.barcodea4{
    width: 8.25in;
    height: 11.6in;
    display: block;
    border: 1px solid #CCC;
    margin: 10px auto;
    padding: 0.1in 0 0 0.1in;
    page-break-after: always;
}

.barcode_non_a4{
    width: 8.45in;
    height: 10.3in;
    display: block;
    border: 1px solid #CCC;
    margin: 10px auto;
    padding-top: 0.1in;
    page-break-after: always;
}

.barcode_non_a4 .barcode-item {
	display: block;
	overflow: hidden;
	text-align: center;
	border: 1px dotted #CCC;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	float: left;
} 

.barcode_non_a4 .head_barcode{
	padding-left: 10px;
	font-weight: bold;
	text-align: left;
}

.barcodea4 .head_barcode{
	padding-left: 10px;
	font-weight: bold;
	text-align: left;
}

.barcode-name{
	display: block;
}

.barcode-price{
	display: block;
}

.barcodea4 .style24 .head_barcode{
	font-size: 14px;
}

.barcodea4 .style18 .head_barcode{
	font-size: 14px;
}

.barcodea4 .style12 .head_barcode{
	font-size: 15px;
}

.barcode_non_a4 .head_barcode{
	font-size: 14px;
}
.barcode_non_a4 .style10 .head_barcode{
	font-size: 16px;
}

.barcodea4 .barcode-item {
	display: block;
	overflow: hidden;
	text-align: center;
	border: 1px dotted #CCC;
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	float: left;
} 

.barcodea4 .style40 {
    width: 1.799in;
    height: 1.003in;
	margin: 0 0.07in;
	padding-top: 0.05in;
} 

.barcode_non_a4 .style30 {
    width: 2.625in;
    height: 1in;
	margin: 0 0.07in;
	padding-top: 0.05in;
} 

.barcode_non_a4 .style30:nth-child(3n+1) {
    margin-left: 0.1in;
} 

.barcodea4 .style24 {
    width: 2.48in;
    height: 1.335in;
	margin-left: 0.079in;
	padding-top: 0.05in;
} 

.barcode_non_a4 .style20 {
    width: 4in;
    height: 1in;
	margin: 0 0.1in;
	padding-top: 0.05in;
} 

.barcodea4 .style18 {
    width: 2.5in;
    height: 1.835in;
	margin-left: 0.079in;
	padding-top: 0.05in;
	font-size: 13px;
	line-height: 20px;
} 

.barcode_non_a4 .style14 {
    width: 4in;
    height: 1.33in;
	margin: 0 0.1in;
	padding-top: 0.1in;
} 

.barcodea4 .style12 {
    width: 2.5in;
    height: 2.834in;
	margin-left: 0.079in;
	padding-top: 0.05in;
	font-size: 14px;
	line-height: 20px;
} 

.barcode_non_a4 .style10 {
    width: 4in;
    height: 2in;
	margin: 0 0.1in;
	padding-top: 0.1in;
	font-size: 14px;
	line-height: 20px;
} 

</style>